<template>
  <div class="customer-meals-wraper">
    <app-modal-confirm
      ref="beacon_delete_modal"
      :modal-header-title="$t('common.delete confirm title')"
      @confirmed="deleteBeacon"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common.delete confirm") }}</p>
      </template>
    </app-modal-confirm>
    <validation-observer
      ref="entry"
      v-slot="{ passes, valid, validated }"
    >
    <form class="form-horizontal" >
      <div class="card card-body">
          <div v-if="idEditing">
            <div class="form-group row">
              <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.id") }}</label>
              <div class="col-xl-5 col-lg-6 col-sm-6 p-t-8"> {{ entry.id }}</div>
            </div>
          </div>
          <validation-provider
            name="status_enabled"
            rules="required"
            v-slot="{ errors, invalid, validated }"
          >
              <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("beacons.status") }} <span
              class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-6 mt-2">
              <app-radio v-for="(item, index) in BEACONS_CONSTANT.STATUS"
                         :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                         :val="item.id" v-model="entry.status_enabled"/>
              <label v-if="invalid && validated" class="error">
                {{ errors[0] }}
              </label>
            </div>
          </div>
          </validation-provider>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("beacons.mansion") }} <span
              class="app-require">＊</span></label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-select
                name="apartment_id"
                rules="required"
                :options-data="meta.apartments"
                v-model="entry.apartment_id"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("beacons.name") }} <span
              class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-input
                name="name"
                rules="required"
                v-model="entry.name"
                type="string128"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("beacons.beacon_id") }} <span
              class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-input
                name="beacon_id"
                rules="required"
                v-model="entry.beacon_id"
                type="string128"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("beacons.serial") }} <span
              class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-input
                name="serial"
                rules="required"
                v-model="entry.serial"
                type="string128"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("beacons.number_of_peoples") }} <span
              class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-input
                name="number_of_peoples"
                :rules="VALIDATE.V_SMALL_INT_REQUIRED"
                v-model="entry.number_of_peoples"
                type="number-length:5"
              />
            </div>
          </div>
        <div class="card-footer row justify-content-end gr-button">
          <button type="button" class="btn btn-default m-r-10" :disabled="!valid && validated"  @click="handlerCancelButton">
            {{ $t('common.cancel') }}
          </button>
          <button type="button" class="btn btn btn-danger m-r-10" :disabled="!valid && validated"  @click="deleteBeaconConfirm" v-if="idEditing">
            {{ $t('apartments.delete') }}
          </button>
          <button class="btn btn-primary" type="button" :disabled="!valid && validated"  @click="createOrUpdate">
            {{idEditing ?  $t('common.save') :  $t('common.create')}}
          </button>
        </div>
      </div>
    </form>
    </validation-observer>
  </div>
</template>

<script>
  import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
  import AppModal from "../../../components/_common/popup/AppModal";
  import AppBasicTable from "@components/_common/list/AppBasicTable";
  import AppModalCustomer from "@components/_common/popup/AppModalCustomer";
  import AppCheckbox from "../../../components/forms/AppCheckbox.vue";
  import {TYPES} from "../../../constants/customer_meals";
  import AppTextarea from "../../../components/forms/AppTextarea";
  import {ROUTES} from "@constants";



  export default {
    name: "FormPage",

    components: {
      AppTextarea,
      AppModal,
      AppDateRangePicker,
      "app-basic-table": AppBasicTable,
      AppModalCustomer,
      "app-checkbox": AppCheckbox,
    },

    data() {
      const id = this.$route.params.id
      return {
        entry : {
          status_enabled : 1
        },
        idEditing: id,
        meta: {
          apartments: [],
        },
      };
    },

    mounted: async function () {
      this.$request.get(this.ENDPOINT.UI_HELPER_CUSTOMER_MEALS).then(res => {
        this.meta = {
          apartments: res.data.apartments,
        }
        this.meta.apartments.unshift({
          id: "",
          name: "--選択--"
        })
      })

      if (this.idEditing) {
        await this.$request.get(this.ENDPOINT.BEACON_EDIT(this.idEditing)).then(res => {
          if (res.hasErrors()) {
            if (res.status === 'error'){
              this.__noticeError(this.$t(res.data.error_msg));
            }
            this.redirectDefaultValue()
          } else {
            this.entry = res.data.data
          }
        })
      }
    },

    methods: {
      deleteBeaconConfirm() {
        this.$refs.beacon_delete_modal.show();
      },
      async deleteBeacon(){
        const res = await this.$request.delete(this.ENDPOINT.BEACON_DELETE(this.idEditing));
        if (!res.hasErrors()) {
          this.__noticeSuccess(this.$t("common.msg delete ok"));
          await this.redirectDefaultValue();
        } else {
          this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          this.$refs.beacon_delete_modal.hide();
          if (res.status === 'error') {
            await this.redirectDefaultValue()
          }
        }
      },
      async createOrUpdate() {
        if (!await this.$refs.entry.validate() || !await this.$refs.entry.validate()) {
          return;
        }
        let res = null;
        let msg = "";
        if (!this.idEditing) {
          res = await this.$request.post(this.ENDPOINT.BEACON_CREATE, this.entry);
          msg = this.$t("common.msg create ok")
        } else {
          res = await this.$request.patch(this.ENDPOINT.BEACON_EDIT(this.idEditing), this.entry);
          msg = this.$t("common.msg update ok")
        }
        if (res.hasErrors()) {
          this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          if (res.status === 'error') {
            await this.redirectDefaultValue()
          }
        } else {
          this.__noticeSuccess(msg);
          await this.redirectDefaultValue();
        }
      },
      handlerCancelButton() {
        if(this.$store.state.setting.prevRouter.name === ROUTES.ADMIN.BEACONS_LIST) {
          this.__redirectBack()
        } else {
          this.redirectDefaultValue()
        }
      },
      async redirectDefaultValue() {
        await this.$router.push({
          name: this.ROUTES.ADMIN.BEACONS_LIST,
          query : {
            'filters.status_enabled.equal': 'all',
            'filters.apartment_id.equal' : 'all'
          }
        });
      },
      redirectBack() {
        this.$router.go(-1);
      },

    },
  };
</script>
